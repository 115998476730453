<div class="xxxl-container">
  <app-order-menu [orderId]="order.id" [isUnboundOrder]="false"></app-order-menu>
</div>
<div class="container">
  <div class="main pt-0">
    <hr>
    <div class ="comments" *ngIf="order?.currentState && (userHasAnyRole([UserRoles.NobinaTrafikledare, UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare, UserRoles.OperatorTrafikledare]))">
      <h2 class="mt-2 mb-4" i18n="@@order.comments">Anteckningar</h2>
      <div *ngIf="order.comment && isRoundTrip" class="allCommentsRoundTrip">{{ parseComment(order.comment) }}</div>
      <div *ngIf="order.comment && !isRoundTrip" class="allComments">{{ parseComment(order.comment) }}</div>
      <div class ="d-flex submit">
        <input id="comments" (input)='onCommentChange($event)' type="text" class="input form-control me-2" placeholder="Lägg till en anteckning" i18n-placeholder="@@order.commments-placeholder"/>
        <button id="send-btn" class="btn btn-primary text-nowrap" [disabled]="!currentComment" (click)="sendComment(currentComment)">Lägg till</button>
      </div>
    </div>
    <h2 class='mt-4' i18n='@@order.route'>Linje</h2>

    <form [formGroup]="orderForm" (ngSubmit)="save()" novalidate class="form mt-4" appInvalidControlScroll>
      <div class="frow" *ngIf="isOperatorDropdownVisible()">
        <div class='row'>
        <div class="col-12 col-md-5 pe-md-4">
          <label class='mb-2' for="operator" i18n="@@order.select-traffic-operator">Välj trafikoperatör</label>
          <select class="form-control form-select" id="operator" formControlName="operator" (change)="OnChangeOperator()">
            <option [ngValue]="null" [disabled]="true" i18n="@@order.select">Välj</option>
            <option id="{{'operator_'}}{{i + 1}}" *ngFor="let op of operators; let i = index" [ngValue]="op">{{ op.name }}</option>
          </select>
          <div *ngIf='operatorCtrl.invalid && (operatorCtrl.dirty || operatorCtrl.touched)' class='text-danger'
               i18n='@@order.traffic-operator-mandatory'>
            Trafikoperatör är obligatoriskt
          </div>
        </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12 col-md-5 pe-md-4">
          <label class='mb-2' for="route" i18n="@@order.select-route">Välj linje</label>
          <select class="form-control form-select" id="route" formControlName="route" (change)="OnChangeRoute()">
            <option value="null" [disabled]="true" i18n="@@order.select">Välj</option>
            <option id="{{'route_'}}{{i + 1}}" *ngFor="let r of routes; let i = index" [ngValue]="r">{{ r.routeName + ' - ' + r.description }}</option>
          </select>
          <div *ngIf='routeCtrl.invalid && (routeCtrl.dirty || routeCtrl.touched)' class='text-danger'
               i18n='@@order.route-mandatory'>
            Linje är obligatoriskt
          </div>
        </div>
      </div>

      <div class='row mt-4'>
        <div class='col-12 col-md-4'>
          <div class='form-radio'>
            <input class='form-radio-input' type='radio' id='oneWayTrip' formControlName='isRoundTrip' [value]='false'
                   (change)='OnIsRoundTrip()'>
            <label class='form-radio-label ms-2' for='oneWayTrip' i18n='@@order.one-way'>Enkel</label>
            <input class='form-radio-input ms-4 mt-3' type='radio' id='roundTrip' formControlName='isRoundTrip'
                   [value]='true' (change)='OnIsRoundTrip()'>
            <label class='form-radio-label ms-2' for='roundTrip' i18n='@@order.round-trip'>Tur & retur</label>
          </div>
        </div>
      </div>

      <div class='row'>
        <div class='col-12 col-md-5 pe-md-4'>
          <label class='form-radio-label' i18n='@@order.trip'>Tur</label>
          <div class='callout-block-lg' id='routeSelector'>
            <span class='callout'></span>
            <div class='row'>
              <div class='col-2'>
                <div class='caption-lt' i18n='@@order.route'>Linje</div>
                <div>{{ routeCtrl?.value?.routeName }}</div>
              </div>
              <div class='col'>
                <div class='caption-lt' i18n='@@order.routevariant'>Linjevariant</div>
                <div *ngIf='routeVariant.id !== null'>{{ routeVariant.name }}</div>
                <div *ngIf='routeCtrl?.value?.routeName && routeVariant.id === null' i18n='@@order.not-selected'>Ej
                  vald
                </div>
              </div>
              <div class='col-2 ps-0'>
                <div class='mt-3' *ngIf='routeVariant.isAdapted' i18n='@@order.adapted'>(Anpassad)</div>
              </div>
              <div class='col-2 me-2'>
                <button type='button' class='small-button' id='routSelectorBtn'
                        [attr.disabled]="(isHistory || routeCtrl?.value?.routeName === null ? 'disabled': null)" (click)='openAdaptRoute(routeVariant)'
                        i18n='@@order.select'>Välj
                </button>
              </div>
            </div>
          </div>
          <div *ngIf='routeVariant.isChanged && routeVariant.id === null' class='text-danger'
               i18n='@@order.routevariant-mandatory'>
            Linjevariant är obligatoriskt
          </div>
        </div>
        <div *ngIf='isRoundTrip' class='col-12 col-md-5'>
          <label class='form-radio-label' i18n='@@order.return'>Retur</label>
          <div class='callout-block-lg' id='returnRouteSelector'>
            <span class='callout'></span>
            <div class='row'>
              <div class='col-2'>
                <div class='caption-lt' i18n='@@order.route'>Linje</div>
                <div>{{ routeCtrl?.value?.routeName }}</div>
              </div>
              <div class='col'>
                <div class='caption-lt' i18n='@@order.routevariant'>Linjevariant</div>
                <div *ngIf='returnRouteVariant.id !== null'>{{ returnRouteVariant.name }}</div>
                <div *ngIf='routeCtrl?.value?.routeName && returnRouteVariant.id === null' i18n='@@order.not-selected'>
                  Ej vald
                </div>
              </div>
              <div class='col-2 ps-0'>
                <div class='mt-3' *ngIf='returnRouteVariant.isAdapted' i18n='@@order.adapted'>(Anpassad)</div>
              </div>
              <div class='col-2 me-2'>
                <button type='button' class='small-button' [attr.disabled]="(isHistory|| routeCtrl?.value?.routeName === null ? 'disabled': null)"
                        (click)='openAdaptRoute(returnRouteVariant, false)' i18n='@@order.select'>Välj
                </button>
              </div>
            </div>
          </div>
          <div *ngIf='returnRouteVariant.isChanged && returnRouteVariant.id === null' class='text-danger'
               i18n='@@order.routevariant-mandatory'>
            Linjevariant är obligatoriskt
          </div>
        </div>
      </div>

      <div class='row'>
        <div class='col-12 col-md-5 pe-md-4 my-4'>
          <label for='tripIntervalMinutes' i18n='@@order.interval'>Intervall i minuter från starthållplats</label>
          <select class='form-select form-control' id='tripIntervalMinutes' formControlName='tripIntervalMinutes'
                  (change)='OnIntervalChange()'>
            <option value='0' i18n='@@order.no-interval'>Inget intervall</option>
            <option *ngFor='let minute of intervalMinutesList' [ngValue]='minute'>{{ minute }}</option>
          </select>
        </div>
      </div>
      <hr>
      <h2 class='my-3' i18n='@@order.times'>Tider</h2>

      <div class='row mb-2'>
        <div class='col-5 pe-4'>
          <div class='form-check' [attr.disabled]='immediateStart.disabled'>
            <input class='form-check-input' type='checkbox' id='immediateStart' formControlName='immediateStart'
                   (change)='OnImmediateStart()'>
            <label class='form-check-label' for='immediateStart' i18n='@@order.immediate-start'>Start omgående</label>
          </div>
        </div>
        <div class='col'>
          <div class="row g-0">
            <div class='form-check tb-form-check-inline' [attr.disabled]='indefiniteEnd.disabled'>
              <input class='form-check-input' type='checkbox' id='indefiniteEnd' formControlName='indefiniteEnd'
                     (change)='OnIndefiniteEnd()'>
              <label class='form-check-label' for='indefiniteEnd' i18n='@@order.indefinite-end'>Tillsvidare</label>
            </div>
            <div class='form-check' [attr.disabled]='specifiedReturntrip.disabled'>
              <input class='form-check-input' type='checkbox' id='specifiedReturntrip'
                     formControlName='specifiedReturntrip' (change)='OnSpecifiedReturnTrip()'>
              <label class='form-check-label' for='specifiedReturntrip' i18n='@@order.specified-returntrip'>Tidsatt
                returresa</label>
            </div>
          </div>
        </div>
      </div>

      <div class='row mb-4'>
        <div class='col-5 pe-4'>
          <ngb-datepicker ngbDatepicker [minDate]='orderstartMinDate' #dpStartDate='ngbDatepicker'
                          formControlName='orderstartDate'
                          (select)='OnOrderstartdateChange()'></ngb-datepicker>
        </div>
        <div class='col'>
          <ngb-datepicker ngbDatepicker [minDate]='orderstopMinDate' #dpEndDate='ngbDatepicker'
                          formControlName='orderendDate'
                          (select)='OnIntervalChange()'></ngb-datepicker>
        </div>
      </div>

      <div class='row'>
        <div class='col-5 pe-4'>
          <label class='d-block mb-2' for='startTimeHour' i18n='@@order.start-time'>Start klockslag</label>
          <select class='form-select form-control time-select' id='startTimeHour' formControlName='startTimeHour'
                  (change)='onStartTimeUpdated()'>
            <option value='' i18n='@@order.select-hour'>Välj timme</option>
            <option *ngFor='let hour of hoursInDayOrderstartList' [ngValue]='hour'>{{ hour | number:'2.0'}}</option>
          </select>
          <select class='form-select form-control time-select d-inline ms-1' id='startTimeMinute'
                  formControlName='startTimeMinute'
                  (change)='onStartTimeUpdated()'>
            <option value='' i18n='@@order.select-minute'>Välj minut</option>
            <option *ngFor='let minute of minutesInHourList' [ngValue]='minute'>{{ minute | number:'2.0'}}</option>
          </select>
        </div>
        <div class='col-5'>
          <label class='d-block mb-2' for='endTimeHour'>
            <ng-container *ngIf='specifiedReturntrip.value' i18n='@@order.return-trip-time'>Returresa start klockslag
            </ng-container>
            <ng-container *ngIf='!specifiedReturntrip.value' i18n='@@order.end-time'>Avslut klockslag</ng-container>
          </label>
          <select class='form-select form-control time-select' id='endTimeHour' formControlName='endTimeHour'>
            <option value='' i18n='@@order.select-hour'>Välj timme</option>
            <option *ngFor='let hour of hoursInDayOrderendList' [ngValue]='hour'>{{ hour | number:'2.0'}}</option>
          </select>
          <select class='form-select form-control time-select d-inline ms-1' id='endTimeMinute'
                  formControlName='endTimeMinute'>
            <option value='' i18n='@@order.select-minute'>Välj minut</option>
            <option *ngFor='let minute of minutesInHourList' [ngValue]='minute'>{{ minute | number:'2.0'}}</option>
          </select>
        </div>
      </div>

      <div class='col me-5'>
        <div *ngIf='((startTimeHour.dirty || startTimeHour.touched) && startTimeHour.invalid) ||
                          ((startTimeMinute.dirty || startTimeMinute.touched) && startTimeMinute.invalid)'
             class='text-danger' i18n='@@order.start-time-mandatory'>
          Starttid är obligatoriskt
        </div>
      </div>
      <div class='col ms-5'>
        <div *ngIf='showEndtimeError()' class='text-danger'>
          {{ endtimeErrorMessage }}
        </div>
        <div *ngIf='showSpecifiedReturnTripTimeError()' class='text-danger'>
          {{specifiedReturntripErrorMessage}}
        </div>
      </div>

      <hr>
      <h2 class='mb-4' i18n='@@order.other'>Övrigt</h2>

      <div class='row mb-4'>
        <div class='col-5 pe-4'>
          <label class='mb-2' for='trainNumber' i18n='@@order.trainNumber'>Tågnummer</label>
          <input class='form-control' type='text' id='trainNumber'
                 formControlName='trainNumber' i18n-placeholder='@@order.placeholder-train-number'>
        </div>
        <div class='col-5 pe-4'>
          <label class='mb-2' for='numberOfVehicles' i18n='@@order.number-of-vehicles'>Antal fordon per avgång</label>
          <input class='form-control' type='number' min='1' id='numberOfVehicles' formControlName='numberOfVehicles'>
          <div *ngIf='(numberOfVehicles.dirty || numberOfVehicles.touched) && numberOfVehicles.invalid'
               class='text-danger'>
            <span *ngIf='numberOfVehicles.invalid && numberOfVehicles.errors.required'
                  i18n='@@order.number-of-vehicles-mandatory'>
              Antal fordon per avgång är obligatoriskt.
            </span>
            <span *ngIf='numberOfVehicles.invalid && (numberOfVehicles.errors.min || numberOfVehicles.errors.max)'
                  i18n='@@order.number-of-vehicles-invalid'>
              Antal fordon per avgång har ett ogiltigt värde.
            </span>
          </div>
        </div>
      </div>

      <div class='row mb-4'>
        <div class='col-5 pe-4'>
          <label class='mb-2' for='vehicleType' i18n='@@order.vehicle-type'>Fordonstyp</label>
          <select class='form-select form-control col-5' id='vehicleType' formControlName='vehicleType'>
            <option value='' [disabled]='true' i18n='@@order.select'>Välj</option>
            <option id="{{'vehicleType_'}}{{i + 1}}" *ngFor='let vt of vehicleTypeDescriptions; let i = index'
                    [ngValue]='vt'>{{ vt }}</option>
          </select>
          <div *ngIf='(vehicleType.dirty || vehicleType.touched) && vehicleType.invalid' class='text-danger'
               i18n='@@order.vehicle-type-mandatory'>
            Fodonstyp är obligatoriskt.
          </div>
        </div>
      </div>

      <div class='row mb-4'>
        <div class='col-5 pe-4'>
          <label class='mb-2' for='reason' i18n='@@order.select-reason'>Välj orsak</label>
          <select class='form-select form-control' id='reason' formControlName='reason'>
            <option value='null' [disabled]='true' i18n='@@order.select'>Välj</option>
            <option id="{{'reason_'}}{{i + 1}}" *ngFor='let r2 of reasons; let i = index'
                    [ngValue]='r2'>{{ r2.description }}</option>
          </select>
          <div *ngIf='reason.invalid && (reason.dirty || reason.touched)' class='text-danger'
               i18n='@@order.reason-mandatory'>
            Orsak är obligatoriskt
          </div>
        </div>
      </div>

      <div class='row mb-4'>
        <div class='col-5 pe-4'>
          <label class='mb-2' for='reasonAdditionalInfo' i18n='@@order.reason-info'>Orsak - fritext</label>
          <textarea class='form-control' placeholder='Frivilligt' id='reasonAdditionalInfo'
                    formControlName='reasonAdditionalInfo' i18n-placeholder='@@order.placeholder-voluntary'></textarea>
        </div>
      </div>

      <div class='row mb-4'>
        <div class='col-5 pe-4'>
          <label class='mb-2' for='referenceId' i18n='@@order.reference'>Referens</label>
          <input class='form-control' type='text' placeholder='Frivilligt egen referens' id='referenceId'
                 formControlName='referenceId' i18n-placeholder='@@order.placeholder-reference-voluntary'>
        </div>
      </div>

      <div class='row mb-5'>
        <div class='col-5 pe-4'>
          <label for='otherRequests' i18n='@@order.other-requests'>Övriga önskemål</label>
          <textarea class='form-control' placeholder='Frivilligt' id='otherRequests' formControlName='otherRequests'
                    i18n-placeholder='@@order.placeholder-voluntary'></textarea>
        </div>
      </div>
      <hr>

      <div class='d-flex gap-3 justify-content-between'>
        <div>
          <button class='btn btn-outline-secondary' type='button' [disabled]='buttonsDisabled' (click)='dismiss()'
                  i18n='@@order.cancel'>Avbryt
          </button>
        </div>
        <div class='d-flex gap-3 justify-content-end'>
          <button
            *ngIf='(userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare, UserRoles.OperatorTrafikledare])
            && (order?.currentState && order.currentState.stateEnum == OrderState.SentToNobina) || (order?.currentState && order.currentState.stateEnum == OrderState.ReceivedByNobina) || (order?.currentState && order.currentState.stateEnum == OrderState.Active) || (order?.currentState && order.currentState.stateEnum == OrderState.Ongoing))'
            id='cancel-order-btn' class='btn btn-outline-danger' type='button' [disabled]='buttonsDisabled || orderForm.enabled'
            (click)='cancelOrder()' i18n='@@order.cancel-order'>Avbeställ
          </button>

          <button
            *ngIf='(userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare]) && order?.currentState && order.currentState.stateEnum == OrderState.Cancelled)'
            id='verify-cancel-order-btn' class='btn btn-outline-danger' type='button' [disabled]='buttonsDisabled'
            (click)='closeOrder()' i18n='@@order.confirm-cancel'>Bekräfta avbeställning
          </button>

          <button id='edit-btn' class='btn btn-primary' type='button' (click)='saveUpdatedForm()'
                  *ngIf='orderForm.enabled && (userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare, UserRoles.OperatorTrafikledare]) 
                    && order?.currentState && (order.currentState.stateEnum == OrderState.ReceivedByNobina || order.currentState.stateEnum == OrderState.SentToNobina || order.currentState.stateEnum == OrderState.Active || order.currentState.stateEnum == OrderState.Ongoing || order.currentState.stateEnum == OrderState.ReportedComplete))'
                  [disabled]='buttonsDisabled || (order?.currentState && (order.currentState.stateEnum == OrderState.Completed || order.currentState.stateEnum == OrderState.Cancelled))'
                  i18n='@@order.edit-order'>Spara ändringar
          </button>

          <button class='btn btn-outline-secondary' type='button' (click)='editForm()'
                  *ngIf='!orderForm.enabled && (userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare, UserRoles.OperatorTrafikledare]) && 
                    order?.currentState && (order.currentState.stateEnum == OrderState.ReceivedByNobina || order.currentState.stateEnum == OrderState.SentToNobina || order.currentState.stateEnum == OrderState.Active || order.currentState.stateEnum == OrderState.Ongoing || order.currentState.stateEnum == OrderState.ReportedComplete))'
                  [disabled]='buttonsDisabled || (order?.currentState && (order.currentState.stateEnum == OrderState.Completed || order.currentState.stateEnum == OrderState.Cancelled))'
                  i18n='@@order.save-edit-order'>Redigera beställning
          </button>

          <button id='activate-btn' class='btn btn-primary' type='button' (click)='activateOrder()'
                  *ngIf='(userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare]) && order?.currentState && (order.currentState.stateEnum <= OrderState.ReceivedByNobina))'
                  [disabled]='buttonsDisabled || (order?.currentState && order.currentState.stateEnum > OrderState.ReceivedByNobina) || orderForm.enabled'
                  i18n='@@order.activate-order'>Aktivera beställning
          </button>

          <button
            *ngIf='(userHasAnyRole([UserRoles.NobinaTrafikledare,UserRoles.NobinaLeveransansvarig, UserRoles.BergkvaraTrafikledare, UserRoles.OperatorTrafikledare]) && !order?.currentState)'
            id='order-btn' class='btn btn-primary' type='submit' [disabled]='buttonsDisabled'
            i18n='@@order.send-order'>Skicka beställning
          </button>

        </div>
      </div>
    </form>
  </div>
</div>
